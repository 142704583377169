const HeaderContainer = () => import('./HeaderContainer');
const CarouselContainer = () => import('./CarouselContainer');
const HeroContainer = () => import('./HeroContainer');
const BannerContainer = () => import('./BannerContainer');
const VerticalContainer = () => import('./VerticalContainer');
const ProductListingContainer = () => import('./ProductListingContainer');
const GridBannersContainer = () => import('./GridBannersContainer');
const FooterContainer = () => import('./FooterContainer');
const DynamicCarouselContainer = () => import('./DynamicCarouselContainer');
const ProductRecommendationsContainer = () => import('./ProductRecommendationsContainer');
const CompareProductsContainer = () => import('./CompareProductsContainer');

export {HeaderContainer as _HeaderContainer};
export {CarouselContainer as _CarouselContainer};
export {HeroContainer as _HeroContainer};
export {BannerContainer as _BannerContainer};
export {VerticalContainer as _VerticalContainer};
export {GridBannersContainer as _GridBannersContainer};
export {FooterContainer as _FooterContainer};
export {ProductListingContainer as _ProductListingContainer};
export {DynamicCarouselContainer as _DynamicCarouselContainer};
export {ProductRecommendationsContainer as _ProductRecommendationsContainer};
export {CompareProductsContainer as _CompareProductsContainer};
