const ImperialTodayAndTomorrow = () => import('./ImperialTodayAndTomorrow');
const OurMission = () => import('./OurMission');
const OurVision = () => import('./OurVision');
const Sustainability = () => import('./Sustainability');
const WhoAreWe = () => import('./WhoAreWe');
const WhoAreWeLinks = () => import('./WhoAreWeLinks');
const WhyImperial = () => import('./WhyImperial');

export {ImperialTodayAndTomorrow as _ImperialTodayAndTomorrow};
export {WhoAreWe as _WhoAreWe};
export {WhoAreWeLinks as _WhoAreWeLinks};
export {Sustainability as _Sustainability};
export {OurVision as _OurVision};
export {WhyImperial as _WhyImperial};
export {OurMission as _OurMission};
