const TimeLine = () => import('./TimeLine');
const CheckoutShipping = () => import('./CheckoutShipping');
const CheckoutPayment = () => import('./CheckoutPayment');
const OrderSummary = () => import('./OrderSummary');
const CheckoutQuote = () => import('./CheckoutQuote');

export {TimeLine as _TimeLine};
export {CheckoutShipping as _CheckoutShipping};
export {CheckoutPayment as _CheckoutPayment};
export {OrderSummary as _OrderSummary};
export {CheckoutQuote as _CheckoutQuote};
